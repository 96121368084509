.exhibmanV2--ABX {
    $product-header-colour: #3598db;
    .product-header {
        h2 a {
            color: $product-header-colour;
        }
    }
    .exhibitor-slide {
        &.exhibitor-slide--ABX {
            .exhibitor-slide__side-flag {
                background: #348ac0;
            }
        }
        &.exhibitor-slide--FMX {
            .exhibitor-slide__side-flag {
                background: #4d8691;
            }
        }
    }
}
