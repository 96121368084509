.enhanced-exhibitors__alphabet > div {
	border-bottom: 1px solid black;
	& > div {
		margin-top: 10px;
		height: 30px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		span {
			cursor: pointer;
			padding-right: 10px;
		}
		& + hr {
			margin-top: 0;
			margin-bottom: 10px;
		}
		@media (max-width: 768px) {
			height: 70px;
			justify-content: center;
			span {
				padding-right: 5px;
			}
		}
		@media screen and (min-width: 768px) and (max-width: 990px) {
			height: 50px;
			justify-content: center;
		}
	}
}
