@import "elem/cta";
@import "elem/horizontal_items";
@import "elem/vertical_items";
@import "elem/bio";
@import "elem/meta_info";
@import "elem/modal";
@import "./override/override";

.exhibitor-drilldown {
	border-top: 1px solid black;
	.exhibitor-drilldown__listing-cta {
		.exhibitor-drilldown__cta {
			position: absolute;
			right: 0;
			z-index: 99;
			margin-top: -1px;
			& + h1 {
				margin-left: -15px;
			}
			@media (max-width: 768px) {
				right: -15px;
			}
		}
	}
	.exhibitor-drilldown__content {
		& > div {
			margin-top: 20px;
		}
		.exhibitor-drilldown__bio {
			margin-top: 0px;
		}
	}
	.exhibitor-drilldown__quickMenu {
		@media (max-width: 768px) {
			display: none;
		}
		& > p {
			font-weight: bold;
			span {
				cursor: pointer;
				font-weight: normal;
				&:hover {
					color: black;
					text-decoration: underline;
					transition: opacity 0.3s;
					-webkit-transition: opacity 0.3s;
				}
				&:first-child {
					padding-left: 20px;
				}
				&:last-child {
					&::after {
						content: "";
					}
				}

				&::after {
					content: "|";
					padding-left: 8px;
					padding-right: 8px;
				}
			}
		}
	}
	/**
		RETROFITS FOR THIS VERSION TO WORK IN BOOTSTRAP 5
	*/

	.exhibitor-drilldown {
		&__content {
			@media (min-width: 990px) {
				border-top: 1px solid black;
			}
			.exhibitor-drilldown__horizontal,
			.exhibitor-drilldown__vertical {
				& > div {
					padding-left: 0;
					padding-right: 0;
				}
				.exhibitor-drilldown__news {
					& > .mob {
						clear: both;
						min-height: 100px;
					}
				}
			}
		}
		&__bio {
			border-top: none;
		}
	}

	//starting points for bs5 grid
	$xs: 0px;
	$sm: 576px;
	$md: 768px;
	$lg: 992px;
	$xl: 1200px;
	$xxl: 1400px;

	.hidden-xs {
		@media (max-width: $sm) {
			display: none !important;
		}
	}
	.visible-xs {
		@media (max-width: $sm) {
			display: block !important;
		}
	}
	.hidden-xxl {
		@media (min-width: $xxl) {
			display: none !important;
		}
	}
	.visible-xxl {
		@media (min-width: $xxl) {
			display: block !important;
		}
	}

	.hidden-sm {
		@media (min-width: $sm) and (max-width: $md) {
			display: none !important;
		}
	}
	.visible-sm {
		@media (min-width: $sm) and (max-width: $md) {
			display: block !important;
		}
	}
	.hidden-md {
		@media (min-width: $md) and (max-width: $lg) {
			display: none !important;
		}
	}
	.visible-md {
		@media (min-width: $md) and (max-width: $lg) {
			display: block !important;
		}
	}
	.hidden-lg {
		@media (min-width: $lg) and (max-width: $xl) {
			display: none !important;
		}
	}
	.visible-lg {
		@media (min-width: $lg) and (max-width: $xl) {
			display: block !important;
		}
	}
	.hidden-xl {
		@media (min-width: $xl) and (max-width: $xxl) {
			display: none !important;
		}
	}
	.visible-xl {
		@media (min-width: $xl) and (max-width: $xxl) {
			display: block !important;
		}
	}
}
