.exhibitor-drilldown__bio {
	min-height: 200px;
	border-top: 1px solid black;
	@media (max-width: 768px) {
		display: none;
	}
	& > div {
		padding: 20px 0px 10px 0px;
	}
	.logo {
		width: auto;
		float: left;
		margin-top: -10px;
		@media (min-width: 768px) {
			margin-right: 30px;
		}
		img {
			padding: 20px;
			max-width: 300px;
			max-height: 300px;
			min-width: 200px;
		}
	}
	.body {
		@media (min-width: 768px) {
			margin-top: 5px;
		}
		.short-bio {
			font-weight: bold;
		}
		.cats {
			margin-top: 10px;
			span {
				font-weight: bold;
			}
		}
	}
}
