$spinner-border-width: 0.25em;
$spinner-height: 10rem;
$spinner-width: 10rem;
.spinner-border {
	display: inline-block;
	width: $spinner-width;
	height: $spinner-height;
	vertical-align: text-bottom;
	border: $spinner-border-width solid currentColor;
	border-right-color: transparent;
	// stylelint-disable-next-line property-blacklist
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}
@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}
