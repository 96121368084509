.exhibitor-drilldown__cta {
	background-color: black;
	border: none;
	color: #fff;
	padding: 5px 10px 5px 10px;
	transition: opacity 0.3s;
	-webkit-transition: opacity 0.3s;
	&:hover {
		opacity: 0.8;
	}
	&:focus {
		border: none;
		outline: none;
		box-shadow: none;
	}
	&--enhanced-listing,
	&--premium-listing {
		position: absolute;
		right: 0;
		margin-top: -1px;
		z-index: 99;
		cursor: default;
		&:hover {
			opacity: 1;
		}
		&:focus {
			border: none;
			outline: none;
			box-shadow: none;
		}
	}
	&--premium-listing {
		background-color: gold;
	}
	&--reset {
		right: 15px;
		position: absolute;
	}
	&--max {
		width: 100%;
		margin: 0px 0px 10px 0px;
		font-size: 2rem;
		font-weight: bold;
	}
	&--micro {
		font-size: 1rem;
		font-weight: normal;
	}
	&--reg {
		background-color: #a92e25;
	}
}
