$downloadable-height-desk: 310px;
$downloadable-height-mob: 150px;
$max-image-height: 195px;
.exhibitor-drilldown__horizontal {
	border-top: 1px solid black;
	h4,
	h5 {
		font-weight: bold;
	}
	@media (min-width: 768px) {
		h4 {
			padding: 10px 0px 10px 0px;
		}
	}
	.exhibitor-drilldown__downloadable {
	    height: auto; //$downloadable-height-desk;
		@media (max-width: 768px) {
			height: auto;
			& > div {
				padding: 5px 0px 5px 0px;
			}
		}
		
		& > div {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 15px 0px 15px 0px;
			p {
				text-align: center;
				margin-top: 25px;
			}
			& > div {
				//width: 100%;
				height: $max-image-height;
				text-align: center;
				a {
					.thumb {
						max-width: 100%;
						height: 100%;
					}
				}
				.fi {
					margin: auto;
					//float: right;
					margin-top: -50px;
					margin-right: -10px;
					.fi-content {
						padding-top: 0px;
						writing-mode: vertical-rl;
					}
				}
			}
		}
	}
	.exhibitor-drilldown__video {
		height: auto;
		//min-height: 290px;
		@media (max-width: 768px) {
			margin-bottom: 20px;
			height: auto;
		}
		& > div {
			position: relative;
			padding-bottom: 75%;
			overflow: hidden;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		p {
			text-align: center;
			font-weight: normal;
		}
	}
}
