.cocktail--ABX {



    #root {
        min-height: auto;
    }

    .innovation {
        .layout--image-inner-hero__text-container > * {
            background: rgba(19, 79, 115, 0.85) !important;
        }
    }

    &.container {
        * {
            font-optical-sizing: auto;
            font-style: normal;
        }

        h1 {
            font-weight: normal; // 'auto' is not valid in CSS, so use 'normal'
            font-size: 65px;

            @media only screen and (max-width: 600px) {
                font-size: 50px;
                margin-top: 25px;
            }
        }
    }

    .header__container {
        .header__container__text {
            &--title {
                // Add styles for title if needed
            }

            &--intro {
                font-size: 18px;
            }
        }
    }

    .steps-container {
        .steps-container__title--thin.secondary-color {
            font-weight: 300;
            font-size: 21px;
        }

        .steps-container__title {
            font-size: 65px;
        }

        .steps-container__title--bold {
            font-weight: bold;
        }

        .steps-container__tags {
            .steps-container__tags__element {
                .steps-container__tags__element__form-check {
                    .steps-container__tags__element__form-check__checkbox-filter__checkbox {
                        .steps-container__tags__element__form-check__checkbox-filter__checkbox__i {
                            .steps-container__tags__element__form-check__checkbox-filter__checkbox__b {
                                // Add styles for inner checkbox elements if needed
                            }
                        }
                    }
                }
            }
        }
    }

    .session-info,
    .session-title {
        font-size: 24px;

        @media only screen and (max-width: 600px) {
            font-size: 18px;
            line-height: 1.5;
        }
    }

    .session-description {
        font-size: 18px;

        @media only screen and (max-width: 600px) {
            font-size: 16px;
        }
    }

    .btn:focus,
    .btn:active:focus {
        box-shadow: none !important;
    }

    h3 {
        font-style: normal;
    }

    .container.position-relative {
        transition: all 0.3s ease;
        // border: 10px solid red; /* Uncomment if needed */
    }

    .card {
        opacity: 0.9;

        &:hover {
            transform: scale(1.01);
            transition: 50ms ease-in-out;
            opacity: 1;
        }
    }

    .cocktail--conaine-step,
    .btn {
        // Add shared styles if needed
    }

    .cocktail--conaine-step:hover,
    .btn:hover,
    .steps-container__tags__element__form-check:hover {
        transform: scale(1.010);
        transition: 200ms linear;
    }
}


    img.shaker-image {
        width: 200px; // Adjust size as needed
        animation: shake 1s infinite;
    }

    @keyframes shake {
        0% { transform: rotate(0deg); }
        25% { transform: rotate(10deg); }
        50% { transform: rotate(0deg); }
        75% { transform: rotate(-10deg); }
        100% { transform: rotate(0deg); }
    }