.main-menu {
	.affix {
		.enhanced-exhibitors__cta--reset {
			right: 10px;
		}
	}
	.affix {
		@media (max-width: 768px) {
			top: 35px;
			width: 100%;
			z-index: 999;
			background: #fff;
			opacity: 1;
			left: 0;
			right: 0;
			padding-left: 10px;
			padding-right: 10px;
			box-shadow: 0px 3px 5px;
		}
		@media (min-width: 768px) {
			top: 60px;
		}
		@media (min-width: 1200px) {
			width: 28%;
			max-width: 350px;
			top: 60px;
		}
	}
	.main-menu__title {
		div {
			height: 20px;
			display: flex;
			align-items: center;
			margin-top: 25px;
			h4 {
				position: absolute;
			}
		}
	}
	.main-menu__ctas {
		.main-menu__expo-pickers {
			div {
				@media (max-width: 768px) {
					&:first-child {
						padding-left: 0;
						padding-right: 5px;
					}
					&:last-child {
						padding-right: 0;
						padding-left: 5px;
					}
				}
				@media (min-width: 768px) {
					position: initial;
					width: initial;
					float: initial;
					padding: 0;
				}
			}
		}
	}
	hr {
		border-color: gainsboro;
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
